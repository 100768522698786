<template>
    <div class="tasks animate__animated animate__fadeInDown">
        <div v-if="list.length>0">
            <div v-for="t,i in list" :key="'task'+i" class="box" :class="t.progress==2?'gray':''" @click="$router.push({name:'task',params:{id:t.id}})">
                <div class="flex top">
                    <div class="flex">
                        <img class="icon" :src="t.taskbag.iconimg" alt="">
                        <span class="title">{{t.taskbag.title}}</span>
                        <span class="order_id">(#{{t.id}})</span>
                    </div>
                    <div class="contract-type" >
                        <a-icon v-if="t.taskbag.type==0" type="folder-open" />
                        <a-icon v-else type="folder" />
                        {{$t('tasks.taskTypes')[t.taskbag.type]}}
                    </div>
                </div>
                <div class="flex info">
                    <div>
                        <div>
                            <span>{{$t('tasks.labels.amount')}}:</span><span>{{t.amount}}</span>
                        </div>
                        <div>
                            <span>{{$t('tasks.labels.profit')}}:</span><span>{{t.amount*t.rate/100}} ({{t.rate}}%)</span>
                        </div>
                        <div>
                            <span>{{$t('tasks.labels.time')}}:</span><span>{{$t('tasks.time',{time:t.period})}} ({{t.fromto}})</span>
                        </div>
                    </div>
                    <div class="progress" v-if="percentStyle=='dashboard'">
                        <a-progress type="dashboard" status="active" stroke-color="#DF474A" :width="80" :gapDegree="0" :percent="t.percent" />
                    </div>
                    <a-icon type="right" />
                </div>
                <div class="progress" v-if="percentStyle=='line'">
                    <a-progress status="active" :stroke-color="{from: '#F2A354',to: '#DF474A',}" type="line" :gapDegree="0" :percent="t.percent" />
                </div>
                <div class="flex bottom">
                    <span>#{{t.oid}}</span>
                    <span>{{$t('tasks.progress')[t.progress]}}</span>
                </div>
            </div>
        </div>
        <div v-if="showLoadingMore"
            :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
        >
            <a-spin v-if="loading" />
            <a-button v-else type="dashed" ghost @click="getData()">
                {{$t('common.more')}}
            </a-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "Tasks",
    data() {
        return {
            fromRouteName: '',
            loading: false,
            showLoadingMore:true,
            page:1,
            pageSize:10,
            list:[],
            percentStyle: 'line',//dashboard,line
        };
    },
    computed:{},
    components: {},
    mounted() {
        this.getData();
    },
    methods: {
        getData(){
            let _this = this;
            this.loading = true;
            this.$http.call(this.$http.api.tasks,{
                data:{page:this.page,size:this.pageSize}
            }).then(res=>{
                _this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    _this.page++;
                    _this.list = _this.list.concat(resp.data);
                    if(resp.data.length<_this.pageSize){
                        _this.showLoadingMore = false;
                    }
                }else{
                    _this.$message.error(resp.data);
                } 
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    _this.$store.commit('setToken','');
                    _this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
    },
};
</script>
<style lang="less" scoped>
.tasks {
    width:100%;
    padding: 0 15px;
    text-align: left;
    .box{
        margin-bottom: 15px;
        .icon{
            width: 24px;
            height: 24px;
        }
        .title{
            padding-left: 3px;
        }
        .contract-type{
            font-size: 0.6rem;
            padding: 1px 3px;
            border-radius: 3px;
        }
        .top{
            // margin: 0 -15px;
            padding: 10px 15px;
            border-bottom: 1px solid #a8a8a8;
        }
        .bottom{
            // margin: 0 -15px;
            padding: 10px 15px;
            font-size: 0.6rem;
            // border-top: 1px solid #d8d8d8;
        }
        .info{
            padding: 15px;
        }
        .progress{
            // width: 95%;
            padding-right: 25px;
        }
        
    }.gray{
        opacity: .6;
    }
}
</style>
